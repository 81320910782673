/**
 * 제목
 * @param {string}  text 텍스트
 * @param {boolean}  extraBold extraBold 여부
 * @returns
 */

import { TextColor } from "types/interface";

interface TtitleT {
  text: string;
  extraBold?: boolean;
  color?: TextColor;
}

const Title = ({ text, extraBold = false, color = "text-body" }: TtitleT) => (
  <h2 className={`text-24 ${color} ${extraBold && "extraBold"}`}>{text}</h2>
);
export default Title;
