/**
 * 입력창
 * @param {string} type input 타입
 * @param {string} placeholder 정의어 ("")
 * @param {boolean} full 가로 100% 여부
 * @param {string | number} value 인풋에 들어가는값
 * @param {React.ChangeEventHandler<HTMLInputElement>} onChange 인풋 값 변경 함수
 */

import React from "react";

interface InputT extends React.ComponentProps<"input"> {
  full?: boolean;
}

const Input = ({ full = true, ...rest }: InputT) => {
  return (
    <input
      className={`text-14 outline-none rounded-lg p-16 semiBold h-56 bg-highlight text-headline ${
        full && "w-full focus:border-2 focus:border-primary focus:px-14"
      }`}
      size={1}
      {...rest}
    ></input>
  );
};

export default Input;
