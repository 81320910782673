import { pointApi } from "Apis/apis";
import Button from "Components/Atoms/Button/button";
import Flex from "Components/Atoms/Flex/flex";
import Space from "Components/Atoms/Space/space";
import Caption from "Components/Atoms/Text/caption";
import HeadLine from "Components/Atoms/Text/headLine";
import Title from "Components/Atoms/Text/title";
import Detailmodal from "Components/Molecules/Modal/detailmodal";
import { modalToggle } from "Store/Slices/modal";
import { useAppDispatch } from "Store/store";
import { comma, dateFormat, getIdx } from "Util/function";
import { Table } from "antd";
import type { TableProps } from "antd";
import { useEffect, useState } from "react";
import { PointT } from "types/interface";
import ModifyModal from "./ModifyModal/modifyModal";

interface ListModalProps {
  row: PointT;
}

const PointModal = ({ row }: ListModalProps) => {
  return (
    <Detailmodal title={`포인트 #${row.index}`} modalNum={1}>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="ID" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.identifier} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="닉네임" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.nickname} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="세부내용" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.usage} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="포인트" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.pointAmount} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="구매상품" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.product} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="일자" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={dateFormat(row.createdAt)} />
        </div>
      </Flex>
    </Detailmodal>
  );
};
const Point = () => {
  const dispatch = useAppDispatch();
  const [pointList, setPointList] = useState<PointT[]>([]);
  const [max, setMax] = useState(0);
  const [selectedRowIdx, setSelectedRowIdx] = useState(-1);

  const getPointList = async () => {
    const {
      data: { data },
    } = await pointApi.get();

    console.log(data);

    setMax(data.pointsCount);
    setPointList(data.points);
  };

  const columns: TableProps<PointT>["columns"] = [
    {
      title: "idx",
      dataIndex: "index",
      key: "index",
      width: 80,
      sortDirections: ["descend"],
      sorter: (a, b) => a.index - b.index,
      render: (a) => getIdx(pointList, a, "index"),
    },
    {
      title: "ID",
      dataIndex: "identifier",
      key: "identifier",
      sorter: (a, b) => a.identifier.localeCompare(b.identifier),
    },
    {
      title: "닉네임",
      dataIndex: "nickname",
      key: "nickname",
      sorter: (a, b) => a.nickname.localeCompare(b.nickname),
    },
    {
      title: "세부내용",
      dataIndex: "usage",
      key: "usage",
      sorter: (a, b) => a.usage.localeCompare(b.usage),
    },
    {
      title: "포인트",
      dataIndex: "pointAmount",
      key: "pointAmount",
      sorter: (a, b) => a.pointAmount - b.pointAmount,
      render: (point) => {
        const isPositive = point > 0;
        return (
          <span className={`${isPositive ? "text-primary" : "text-red-500"}`}>
            {isPositive && "+"}
            {comma(point)}
          </span>
        );
      },
    },
    {
      title: "구매상품",
      dataIndex: "product",
      key: "product",
      sorter: (a, b) => a.product.localeCompare(b.product),
    },
    {
      title: "일자",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 135,
      render: (date) => dateFormat(date),
    },
  ];

  useEffect(() => {
    getPointList();
  }, []);

  return (
    <div>
      <HeadLine text="포인트 관리" extraBold />
      <Space y={37} />
      <div className="bg-white rounded-xl p-32">
        <Flex>
          <Title text={`${max}개의 항목`} />
          <Space x={23} />
          <Button
            color="bg-progress"
            onClick={() => {
              dispatch(modalToggle(2));
            }}
            text="포인트 지급 / 차감"
            full={false}
          />
        </Flex>
        <Space y={23} />
        <Table
          rowKey="index"
          size="middle"
          pagination={{ position: ["bottomCenter"] }}
          columns={columns}
          dataSource={pointList}
          onRow={(record) => {
            return {
              onClick: (e) => {
                setSelectedRowIdx(record.index);
                dispatch(modalToggle(1));
              },
            };
          }}
        />
      </div>
      {selectedRowIdx !== -1 && (
        <PointModal
          row={
            pointList.find((el) => el.index === selectedRowIdx) ||
            ({} as PointT)
          }
        />
      )}
      <ModifyModal />
    </div>
  );
};

export default Point;
