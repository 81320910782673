import React, { useState } from "react";

import HeadLine from "Components/Atoms/Text/headLine";
import useInput from "Util/Hooks/useInput";
import Button from "Components/Atoms/Button/button";
import InputWithLabel from "Components/Molecules/InputWithLabel/inputWithLabel";
import LoginTemplate from "./modifyModalTemplate";
import { loginApi } from "Apis/apis";
import { accessToken, refreshToken } from "Util/function";
import useSetUser from "Util/Hooks/useTokenCheck";
import { useNavigate } from "react-router-dom";

const ModifyModal = () => {
  const navigate = useNavigate();
  const set = useSetUser();

  const { setValue: setId, ...restId } = useInput("test@test.com");
  const { setValue: setPw, ...restPw } = useInput("test");

  const [msg, setMsg] = useState("");

  const login: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const account = {
      email: restId.value,
      password: restPw.value,
    };
    try {
      const { data } = await loginApi(account);
      accessToken.set(data.data.accessToken);
      refreshToken.set(data.data.refreshToken);
      set();
      navigate("/topic");
    } catch (error) {
      const err: any = error;
      setMsg(err.response.data.message);
    }
  };

  return (
    <LoginTemplate
      Title={<HeadLine extraBold text="포인트 지급 / 차감" />}
      Id={
        <InputWithLabel
          text="이메일"
          type="email"
          placeholder="clpick@gmail.com"
          {...restId}
        />
      }
      Pw={
        <InputWithLabel
          text="비밀번호"
          type="password"
          placeholder="******"
          {...restPw}
        />
      }
      Msg={
        <p className="absolute left-0 top-246 text-warning text-18 semiBold">
          {msg}
        </p>
      }
      Btn={
        <div className="text-center px-50">
          <Button type="submit" text="로그인" />
        </div>
      }
      login={login}
    />
  );
};

export default ModifyModal;
