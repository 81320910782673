/**
 * 설명
 * @param {string}  text 텍스트
 * @param {boolean}  extraBold extraBold 여부
 * @returns
 */

import { TextColor } from "types/interface";

interface CaptionT {
  text: string | number;
  extraBold?: boolean;
  color?: TextColor;
}

const Caption = ({
  text,
  extraBold = false,
  color = "text-caption",
}: CaptionT) => (
  <p className={`text-16 ${color} ${extraBold && "extraBold"}`}>{text}</p>
);
export default Caption;
