import { checkTokenApi, getAccessApi } from "Apis/apis";
import dayjs from "dayjs";

/**
 * 숫자 콤마찍기
 * @param {number} number 숫자
 * @returns {string} 컴마찍힌 문자열숫자
 */
export const comma = (number: number): string => {
  const commaNumber = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return commaNumber;
};

/**
 * set폼데이터
 * @param {FormData} formData - 폼데이터
 * @param {} data - 객체
 * @param {} parentKey 키이름(재귀시에만 활용)
 */
export const setFormData = (
  formData: FormData,
  data: any,
  parentKey?: string
) => {
  Object.keys(data).forEach((key) => {
    const val = data[key]; // value

    if (parentKey) key = `${parentKey}[${key}]`; // 값이 객체일때 해당객체에 접근

    if (val instanceof Object && !Array.isArray(val)) {
      return setFormData(formData, val, key);
    }

    if (Array.isArray(val)) {
      val.forEach((v, idx) => {
        if (v instanceof Object) {
          setFormData(formData, v, `${key}[${idx}]`);
        } else {
          formData.append(`${key}[${idx}]`, v);
        }
      });
    } else {
      formData.append(key, val);
    }
  });
};

// accessToken
export const accessToken = {
  get: () => localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN!) || "",
  set: (token: string) =>
    localStorage.setItem(process.env.REACT_APP_ACCESS_TOKEN!, token),
  del: () => localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN!),
};
// refrestToken
export const refreshToken = {
  get: () => localStorage.getItem(process.env.REACT_APP_REFREST_TOKEN!) || "",
  set: (token: string) =>
    localStorage.setItem(process.env.REACT_APP_REFREST_TOKEN!, token),
  del: () => localStorage.removeItem(process.env.REACT_APP_REFREST_TOKEN!),
};

//access토큰 재발급
export const reAccess = async () => {
  const { data } = await getAccessApi();
  return data.data.accessToken.accessToken;
};

// 날짜포맷
export const dateFormat = (data: string) =>
  data ? dayjs(data).format("YY/MM/DD HH:mm") : "";

// 테이블idx
/**
 *
 * @param {any[]} arr // 전체배열
 * @param {number} idx
 * @param {string} idxString
 * @returns
 */
export const getIdx = (arr: any[], idx: number, idxString: string) => {
  const index = arr.findIndex((el) => el[idxString] === idx);
  return index + 1;
};

export const reAccessToken = async (api: any) => {
  const token = await reAccess(); // 재발금
  accessToken.set(token); // localStorage에 적용
  const { data } = await checkTokenApi(); // 검증
  console.log(data);
  // 성공시
  if (data.code === "SUCCESS") {
    api();
  }
  console.log(data);
};
