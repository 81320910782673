import { configureStore, combineReducers } from "@reduxjs/toolkit";

import modal from "./Slices/modal";
import user from "./Slices/user";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

const reducer = combineReducers({
  user,
  modal,
});

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type ReducerType = ReturnType<typeof reducer>;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<ReducerType> = useSelector;

export default store;
