import { reportApi } from "Apis/apis";
import Space from "Components/Atoms/Space/space";
import HeadLine from "Components/Atoms/Text/headLine";
import Title from "Components/Atoms/Text/title";
import { dateFormat, getIdx } from "Util/function";
import { Table } from "antd";
import type { TableProps } from "antd";
import { useEffect, useState } from "react";
import { ReportT, ReportTypeT } from "types/interface";
import { ReactComponent as CheckActive } from "Util/Svgs/check_active.svg";
import { ReactComponent as Check } from "Util/Svgs/check.svg";
import Detailmodal from "Components/Molecules/Modal/detailmodal";
import Flex from "Components/Atoms/Flex/flex";
import Caption from "Components/Atoms/Text/caption";
import { useAppDispatch } from "Store/store";
import { modalToggle } from "Store/Slices/modal";

interface ReportModalProps {
  row: ReportT;
  actionToggle: (idx: number, type: ReportTypeT) => {};
}
const REPORT_TYPE: { [idx: string]: string } = {
  post: "포스트",
  postComment: "댓글",
  postCommentReply: "답글",
};
const ReportModal = ({ row, actionToggle }: ReportModalProps) => {
  return (
    <Detailmodal title={`신고 #${row.index}`} modalNum={1}>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="신고타입" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={REPORT_TYPE[row.type]} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="신고자" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.reporterIdentifier} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="피신고자" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.reportedIdentifier} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="사유" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.reason} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="생성일자" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={dateFormat(row.createdAt)} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="조치" />
        </div>
        <div className="flex-1">
          <button onClick={() => actionToggle(row.index, row.type)}>
            {row.isActed ? <CheckActive /> : <Check />}
          </button>
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="조치일자" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={dateFormat(row.actedAt)} />
        </div>
      </Flex>
    </Detailmodal>
  );
};
const Report = () => {
  const dispatch = useAppDispatch();
  const [reportList, setReportList] = useState<ReportT[]>([]);
  const [max, setMax] = useState(0);
  const [selectedRowIdx, setSelectedRowIdx] = useState(-1);

  const getReportList = async () => {
    const {
      data: { data },
    } = await reportApi.get();

    console.log(data);

    setMax(data.reportsCount);
    setReportList(data.reports);
  };

  const actionToggle = async (reportId: number, reportType: ReportTypeT) => {
    const confirm = window.confirm("조치상태를 변경 하시겠습니까?");
    if (!confirm) return;

    const { data } = await reportApi.putAction(reportId, reportType);
    data.code === "SUCCESS" && getReportList();
  };

  const columns: TableProps<ReportT>["columns"] = [
    {
      title: "idx",
      dataIndex: "index",
      key: "index",
      width: 80,
      sortDirections: ["descend"],
      sorter: (a, b) => a.index - b.index,
      render: (a) => getIdx(reportList, a, "index"),
    },
    {
      title: "신고타입",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => a.type.localeCompare(b.type),
      render: (type) => REPORT_TYPE[type],
    },
    {
      title: "신고자",
      dataIndex: "reporterIdentifier",
      key: "reporterIdentifier",
      sorter: (a, b) =>
        a.reporterIdentifier.localeCompare(b.reporterIdentifier),
    },
    {
      title: "피신고자",
      dataIndex: "reportedIdentifier",
      key: "reportedIdentifier",
      sorter: (a, b) =>
        a.reportedIdentifier.localeCompare(b.reportedIdentifier),
    },
    {
      title: "사유",
      dataIndex: "reason",
      key: "reason",
      sorter: (a, b) => a.reason.localeCompare(b.reason),
    },
    {
      title: "생성일자",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 135,
      render: (date) => dateFormat(date),
    },
    {
      title: "조치",
      dataIndex: "isActed",
      key: "isActed",
      width: 100,
      render: (isActed, report) => (
        <button onClick={() => actionToggle(report.index, report.type)}>
          {isActed ? <CheckActive /> : <Check />}
        </button>
      ),
    },
    {
      title: "조치일자",
      dataIndex: "actedAt",
      key: "actedAt",
      width: 135,
      render: (date) => dateFormat(date),
    },
  ];

  useEffect(() => {
    getReportList();
  }, []);

  return (
    <div>
      <HeadLine text="신고 리포트" extraBold />
      <Space y={37} />
      <div className="p-32 bg-white rounded-xl">
        <Title text={`${max}개의 신고`} />
        <Space y={23} />
        <Table
          rowKey="index"
          size="middle"
          pagination={{ position: ["bottomCenter"] }}
          columns={columns}
          dataSource={reportList}
          onRow={(record) => {
            return {
              onClick: (e) => {
                const target: any = e.target;
                const value: any = target.tagName;
                setSelectedRowIdx(record.index);
                const isAction =
                  value.includes("rect") || value.includes("path"); // 스위치 클릭 여부
                !isAction && dispatch(modalToggle(1));
              },
            };
          }}
        />
      </div>
      {selectedRowIdx !== -1 && (
        <ReportModal
          row={
            reportList.find((el) => el.index === selectedRowIdx) ||
            ({} as ReportT)
          }
          actionToggle={actionToggle}
        />
      )}
    </div>
  );
};

export default Report;
