import { goodsApi } from "Apis/apis";
import Button from "Components/Atoms/Button/button";
import Flex from "Components/Atoms/Flex/flex";
import Space from "Components/Atoms/Space/space";
import HeadLine from "Components/Atoms/Text/headLine";
import Title from "Components/Atoms/Text/title";
import { getIdx } from "Util/function";
import { Table } from "antd";
import type { TableProps } from "antd";
import { useEffect, useState } from "react";
import { GoodsT } from "types/interface";
import { ReactComponent as Pencil } from "Util/Svgs/pencil.svg";
import { ReactComponent as PencilActive } from "Util/Svgs/pencil_active.svg";
import Detailmodal from "Components/Molecules/Modal/detailmodal";
import Caption from "Components/Atoms/Text/caption";
import { useAppDispatch } from "Store/store";
import { modalToggle } from "Store/Slices/modal";

type TableRowSelection<T> = TableProps<T>["rowSelection"];

interface ListModalProps {
  row: GoodsT;
}

const GoodsModal = ({ row }: ListModalProps) => {
  return (
    <Detailmodal title={`포인트 #${row.index}`} modalNum={1}>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="기프티콘 번호" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.productId} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="브랜드" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.brandName} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="기프티콘 이름" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.productName} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="카테고리" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.category} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="유의사항" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.notice} />
        </div>
      </Flex>
    </Detailmodal>
  );
};

const Goods = () => {
  const dispatch = useAppDispatch();
  const [goodsList, setGoodsList] = useState<GoodsT[]>([]);
  const [max, setMax] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRowIdx, setSelectedRowIdx] = useState(-1);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const actionToggle = () => {
    console.log(1);
  };

  const rowSelection: TableRowSelection<GoodsT> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const getGoodsList = async () => {
    const {
      data: { data },
    } = await goodsApi.get();

    console.log(data);

    setMax(data.productsCount);
    setGoodsList(data.products);
  };

  const columns: TableProps<GoodsT>["columns"] = [
    {
      title: "idx",
      dataIndex: "index",
      key: "index",
      width: 80,
      sortDirections: ["descend"],
      sorter: (a, b) => a.index - b.index,
      render: (a) => getIdx(goodsList, a, "index"),
    },
    {
      title: "기프티콘 번호",
      dataIndex: "productId",
      key: "productId",
      sorter: (a, b) => a.productId.localeCompare(b.productId),
    },
    {
      title: "브랜드",
      dataIndex: "brandName",
      key: "brandName",
      sorter: (a, b) => a.brandName.localeCompare(b.brandName),
    },
    {
      title: "기프티콘 이름",
      dataIndex: "productName",
      key: "productName",
      sorter: (a, b) => a.productName.localeCompare(b.productName),
    },
    {
      title: "카테고리",
      dataIndex: "category",
      key: "category",
      sorter: (a, b) => a.category.localeCompare(b.category),
    },
    {
      title: "유의사항",
      dataIndex: "notice",
      key: "notice",
      width: 100,
      render: (notice, report) => (
        <button onClick={actionToggle}>
          {notice ? <PencilActive /> : <Pencil />}
        </button>
      ),
    },
  ];

  useEffect(() => {
    getGoodsList();
  }, []);

  return (
    <div>
      <HeadLine text="별샵상품 관리" extraBold />
      <Space y={37} />
      <div className="bg-white rounded-xl p-32">
        <Flex>
          <Title text={`${max}개의 항목`} />
          <Space x={23} />
          <Button
            color="bg-attention"
            onClick={() => {}}
            text="선택한 기프티콘 삭제"
            full={false}
          />
        </Flex>
        <Space y={23} />
        <Table
          rowKey="index"
          rowSelection={rowSelection}
          size="middle"
          pagination={{ position: ["bottomCenter"] }}
          columns={columns}
          dataSource={goodsList}
          onRow={(record) => {
            return {
              onClick: (e) => {
                setSelectedRowIdx(record.index);
                dispatch(modalToggle(1));
              },
            };
          }}
        />
      </div>
      {selectedRowIdx !== -1 && (
        <GoodsModal
          row={
            goodsList.find((el) => el.index === selectedRowIdx) ||
            ({} as GoodsT)
          }
        />
      )}
    </div>
  );
};

export default Goods;
