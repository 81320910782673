import React from "react";

import Flex from "Components/Atoms/Flex/flex";
import Space from "Components/Atoms/Space/space";
import Plainmodal from "Components/Molecules/Modal/plainmodal";

interface LoginTemplateProps {
  Title: JSX.Element;
  Id: JSX.Element;
  Pw: JSX.Element;
  Msg: JSX.Element;
  Btn: JSX.Element;
  login: React.FormEventHandler<HTMLFormElement>;
}

const LoginTemplate = ({
  Title,
  Id,
  Pw,
  Msg,
  Btn,
  login,
}: LoginTemplateProps) => {
  return (
    <Plainmodal modalNum={2}>
      <Flex full vertical>
        {Title}
      </Flex>
      <Space y={25} />
      <form onSubmit={login} className="relative">
        {Id}
        <Space y={40} />
        {Pw}
        <Space y={40} />
        {Pw}
        <Space y={16} />
        {Msg}
        <Space y={64} />
        {Btn}
      </form>
    </Plainmodal>
  );
};

export default LoginTemplate;
