import axios, { AxiosResponse } from "axios";
import { accessToken, reAccessToken } from "Util/function";
const REAL_DOMAIN = "https://dev-admin.clpick.us/";
const v1 =
  window.location.host === REAL_DOMAIN
    ? process.env.REACT_APP_API_V1
    : process.env.REACT_APP_DEV_API_V1;

const instance = () => {
  return axios.create({
    baseURL: v1,
    headers: { Authorization: `Bearer ${accessToken.get()}` },
  });
};

const errorHandleApi = async (
  api: () => Promise<AxiosResponse<any, any>>
): Promise<any> => {
  return await api().catch(async (err: any) => {
    // 에러안의 데이터 접근
    // || {} 안쓰면 err.response.data 없을때 로직 끝남
    const { details = null } = err.response.data || {};
    details && alert(details);

    // 토큰 만료시
    err.response.data.message === "EXPIRED_ACCESS_TOKEN" && reAccessToken(api);

    console.log(err);
    // throw err;
  });
};

// get api
export const getApi = async (url: string) =>
  errorHandleApi(() => instance().get(url));

// post api
export const postApi = async (url: string, body?: any) =>
  errorHandleApi(() => instance().post(url, body));

// put api
export const putApi = async (url: string, body?: any) =>
  errorHandleApi(() => instance().put(url, body));

// delete api
export const deleteApi = async (url: string) =>
  errorHandleApi(() => instance().delete(url));

//
export const deleteBody = async (url: string, body: any) => {
  const data = await axios({
    method: "DELETE",
    url: `${v1}${url}`,
    data: body,
    headers: { Authorization: `Bearer ${accessToken.get()}` },
  });
  return data;
};
