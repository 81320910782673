import { useState } from "react";

const useInput = (init: string) => {
  const [value, setValue] = useState<string>(init);
  // const dispatch = useAppDispatch();

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) =>
    setValue(event.target.value);

  return { value, setValue, onChange };
};

export default useInput;
