import Caption from "Components/Atoms/Text/caption";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "Store/Slices/user";
import { ReducerType, useAppDispatch } from "Store/store";

import { User } from "types/interface";
import { accessToken, refreshToken } from "Util/function";

const Header = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user: User = useSelector((state: ReducerType) => state.user);

  const logOut = () => {
    accessToken.del();
    refreshToken.del();
    dispatch(logout());
    navigate("/");
  };

  return (
    <div
      id="header"
      className="flex flex-col items-end justify-center px-24 h-70"
    >
      <Caption text={user.email} />
      <button onClick={logOut}>
        <Caption text="로그아웃" color="text-primary" />
      </button>
    </div>
  );
};

export default Header;
