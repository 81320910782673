import { ReactNode } from "react";
import { BgColor } from "types/interface";

/**
 * 버튼 atomic
 * @param {string} text 텍스트
 * @param {boolean} full Width 100% (false)
 * @param {BgColor} color 색상
 */

interface ButtonProps extends React.ComponentProps<"button"> {
  text: string | ReactNode;
  full?: boolean;
  color?: BgColor;
  standardItem?: string;
}

const Button = ({
  text,
  full = true,
  color = "bg-primary",
  ...props
}: ButtonProps) => {
  return (
    <button
      {...props}
      className={`${color} ${
        full ? "w-full rounded-lg" : "px-33 py-8 rounded-xl"
      } h-56 text-white`}
    >
      {text}
    </button>
  );
};

export default Button;
