import { accountApi } from "Apis/apis";
import Flex from "Components/Atoms/Flex/flex";
import Space from "Components/Atoms/Space/space";
import Caption from "Components/Atoms/Text/caption";
import HeadLine from "Components/Atoms/Text/headLine";
import Title from "Components/Atoms/Text/title";
import Detailmodal from "Components/Molecules/Modal/detailmodal";
import { modalToggle } from "Store/Slices/modal";
import { useAppDispatch } from "Store/store";
import { comma, dateFormat, getIdx } from "Util/function";
import { Table } from "antd";
import type { TableProps } from "antd";
import { useEffect, useState } from "react";
import { AccountT } from "types/interface";

interface CommentModalProps {
  row: AccountT;
}

const CommnetModal = ({ row }: CommentModalProps) => {
  return (
    <Detailmodal title={`계정 #${row.index}`} modalNum={1}>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="ID" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.identifier} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="닉네임" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.nickname} />
        </div>
      </Flex>
      <Flex py={12} vertical y="items-start">
        <div className="w-180">
          <Caption text="썸네일" />
        </div>
        <Space y={12} />
        <div className="flex-1">
          <img src={row.profileImage} alt="" />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="보유포인트" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={comma(row.pointAmount)} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="팔로워" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.followersCount} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="리스트" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.listsCount} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="공개포스트" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.publicListsCount} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="비공개포스트" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.privateListsCount} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="생성일자" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={dateFormat(row.createdAt)} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="마지막 접속" />
        </div>
        <div className="flex-1">
          <Caption
            color="text-headline"
            text={dateFormat(row.lastLoggedInAt)}
          />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="이메일" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.email} />
        </div>
      </Flex>
    </Detailmodal>
  );
};

const Account = () => {
  const dispatch = useAppDispatch();
  const [accountList, setAccountList] = useState<AccountT[]>([]);
  const [max, setMax] = useState(0);
  const [selectedRowIdx, setSelectedRowIdx] = useState(-1);

  const getAccountList = async () => {
    const {
      data: { data },
    } = await accountApi.get();

    console.log(data);

    setMax(data.usersCount);
    setAccountList(data.users);
  };

  const columns: TableProps<AccountT>["columns"] = [
    {
      title: "idx",
      dataIndex: "index",
      key: "index",
      sortDirections: ["descend"],
      sorter: (a, b) => a.index - b.index,
      render: (a) => getIdx(accountList, a, "index"),
    },
    {
      title: "ID",
      dataIndex: "identifier",
      key: "identifier",
      sorter: (a, b) => a.identifier.localeCompare(b.identifier),
    },
    {
      title: "닉네임",
      dataIndex: "nickname",
      key: "nickname",
      sorter: (a, b) => a.nickname.localeCompare(b.nickname),
    },
    {
      title: "썸네일",
      dataIndex: "profileImage",
      key: "profileImage",
      width: 150,
      render: (thumb) => <img src={thumb} alt="" />,
    },
    {
      title: "보유포인트",
      dataIndex: "pointAmount",
      key: "pointAmount",
      sorter: (a, b) => a.pointAmount - b.pointAmount,
      render: (point) => comma(point),
    },
    {
      title: "팔로워",
      dataIndex: "followersCount",
      key: "followersCount",
      sorter: (a, b) => a.followersCount - b.followersCount,
    },
    {
      title: "리스트",
      dataIndex: "listsCount",
      key: "listsCount",
      sorter: (a, b) => a.listsCount - b.listsCount,
    },
    {
      title: "공개포스트",
      dataIndex: "publicListsCount",
      key: "publicListsCount",
      sorter: (a, b) => a.publicListsCount - b.publicListsCount,
    },
    {
      title: "비공개포스트",
      dataIndex: "privateListsCount",
      key: "privateListsCount",
      sorter: (a, b) => a.privateListsCount - b.privateListsCount,
    },
    {
      title: "생성일자",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: (date) => dateFormat(date),
    },
    {
      title: "마지막 접속",
      dataIndex: "lastLoggedInAt",
      key: "lastLoggedInAt",
      sorter: (a, b) => a.lastLoggedInAt.localeCompare(b.lastLoggedInAt),
      render: (date) => dateFormat(date) || "",
    },
    {
      title: "이메일",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
  ];

  useEffect(() => {
    getAccountList();
  }, []);

  return (
    <div>
      <HeadLine text="계정관리" extraBold />
      <Space y={37} />
      <div className="p-32 bg-white rounded-xl">
        <Title text={`${max}개의 계정`} />
        <Space y={23} />
        <Table
          rowKey="index"
          size="middle"
          pagination={{ position: ["bottomCenter"] }}
          columns={columns}
          dataSource={accountList}
          onRow={(record) => {
            return {
              onClick: (e) => {
                setSelectedRowIdx(record.index);
                dispatch(modalToggle(1));
              },
            };
          }}
        />
      </div>
      {selectedRowIdx !== -1 && (
        <CommnetModal
          row={
            accountList.find((el) => el.index === selectedRowIdx) ||
            ({} as AccountT)
          }
        />
      )}
    </div>
  );
};

export default Account;
