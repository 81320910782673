/**
 * 헤드라인
 * @param {string}  text 텍스트
 * @param {boolean}  extraBold extraBold 여부
 * @returns
 */

interface HeadLineT {
  text: string;
  extraBold?: boolean;
}

const HeadLine = ({ text, extraBold = false }: HeadLineT) => (
  <h1 className={`text-32 text-headline ${extraBold && "extraBold"}`}>
    {text}
  </h1>
);
export default HeadLine;
