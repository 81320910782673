import Header from "./header";
import Sidebar from "./sidebar";
import Flex from "Components/Atoms/Flex/flex";

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div id="layout" className="overflow-hidden">
      <Flex>
        <Sidebar />
        <div className="flex flex-1 flex-col w-full h-[100vh]">
          <Header />
          <div className={`flex-1 overflow-auto main p-30 bg-highlight`}>
            {children}
          </div>
        </div>
      </Flex>
    </div>
  );
};

export default Layout;
