/**
 * 본문
 * @param {string}  text 텍스트
 * @param {boolean}  extraBold extraBold 여부
 * @returns
 */

import { TextColor } from "types/interface";

interface BodyT {
  text: string;
  extraBold?: boolean;
  color?: TextColor;
}

const Body = ({ text, extraBold = false, color = "text-body" }: BodyT) => (
  <p className={`text-20 ${extraBold && "extraBold"} ${color}`}>{text}</p>
);
export default Body;
