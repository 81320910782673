import { useSelector } from "react-redux";
import { ReducerType } from "Store/store";

/**
 * 전체화면 백그라운드
 * @param  modal 모달넘버
 * @param  child 안에 보일 엘리먼트
 * @returns
 */
const PlainModal = ({
  children,
  modalNum,
}: {
  children: React.ReactNode;
  modalNum: number;
}) => {
  const open = useSelector((state: ReducerType) => state.modal);

  if (modalNum !== open) return null;
  return (
    <div className="fixed w-full h-[100vh] bg-black1/2 top-0 left-0 z-50">
      {/* 버블링 막음 */}
      <div
        onClick={(e) => e.stopPropagation()}
        className="fixed p-center bg-white p-50 rounded-3xl max-w-[700px] min-w-[500px]"
      >
        {children}
      </div>
    </div>
  );
};

export default PlainModal;
