import { commentApi } from "Apis/apis";
import Flex from "Components/Atoms/Flex/flex";
import Space from "Components/Atoms/Space/space";
import Caption from "Components/Atoms/Text/caption";
import HeadLine from "Components/Atoms/Text/headLine";
import Title from "Components/Atoms/Text/title";
import Detailmodal from "Components/Molecules/Modal/detailmodal";
import { modalToggle } from "Store/Slices/modal";
import { useAppDispatch } from "Store/store";
import { dateFormat, getIdx } from "Util/function";
import { Table, Switch } from "antd";
import type { TableProps } from "antd";
import { useEffect, useState } from "react";
import { CommentT, CommentType } from "types/interface";
interface CommentModalProps {
  row: CommentT;
  activeToggle: (idx: number, type: CommentType) => {};
}

const CommnetModal = ({ row, activeToggle }: CommentModalProps) => {
  return (
    <Detailmodal title={`댓글 #${row.index}`} modalNum={1}>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="ID" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.identifier} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="닉네임" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.nickname} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="유형" />
        </div>
        <div className="flex-1">
          <Caption
            color="text-headline"
            text={row.type === "comment" ? "댓글" : "답글"}
          />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="댓글내용" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.content} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="포스트제목" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.postTitle} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="생성일자" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={dateFormat(row.createdAt)} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="삭제일자" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.deletedAt || ""} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="숨김" />
        </div>
        <Switch
          onChange={() => {
            activeToggle(row.index, row.type);
          }}
          checked={row.isHidden}
        />
      </Flex>
    </Detailmodal>
  );
};

const Comment = () => {
  const dispatch = useAppDispatch();

  const [commentList, setCommentList] = useState<CommentT[]>([]);
  const [max, setMax] = useState(0);
  const [selectedRowIdx, setSelectedRowIdx] = useState(-1);

  const getCommentList = async () => {
    const {
      data: { data },
    } = await commentApi.get();

    console.log(data);

    setMax(data.commentsCount);
    setCommentList(data.comments);
  };

  const activeToggle = async (commentId: number, commentType: CommentType) => {
    const confirm = window.confirm("숨김상태를 변경 하시겠습니까?");
    if (!confirm) return;

    const { data } = await commentApi.putHidden(commentId, commentType);
    data.code === "SUCCESS" && getCommentList();
  };

  const columns: TableProps<CommentT>["columns"] = [
    {
      title: "idx",
      dataIndex: "index",
      key: "index",
      width: 80,
      sortDirections: ["descend"],
      sorter: (a, b) => a.index - b.index,
      render: (a) => getIdx(commentList, a, "index"),
    },
    {
      title: "ID",
      dataIndex: "identifier",
      key: "identifier",
      sorter: (a, b) => a.identifier.localeCompare(b.identifier),
    },
    {
      title: "닉네임",
      dataIndex: "nickname",
      width: 100,
      key: "nickname",
      sorter: (a, b) => a.nickname.localeCompare(b.nickname),
    },
    {
      title: "유형",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => a.type.localeCompare(b.type),
      render: (type) => (type === "comment" ? "댓글" : "답글"),
    },
    {
      title: "댓글내용",
      dataIndex: "content",
      key: "content",
      sorter: (a, b) => a.content.localeCompare(b.content),
    },
    {
      title: "포스트제목",
      dataIndex: "postTitle",
      key: "postTitle",
      sorter: (a, b) => a.postTitle.localeCompare(b.postTitle),
    },
    {
      title: "생성일자",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 135,
      render: (date) => dateFormat(date),
    },
    {
      title: "삭제일자",
      dataIndex: "deletedAt",
      key: "deletedAt",
      width: 135,
      render: (date) => dateFormat(date) || "",
    },
    {
      title: "숨김",
      dataIndex: "isHidden",
      key: "isHidden",
      width: 100,
      render: (hidden, comment) => (
        <Switch
          onChange={() => activeToggle(comment.index, comment.type)}
          checked={hidden}
        />
      ),
    },
  ];

  useEffect(() => {
    getCommentList();
  }, []);

  return (
    <div>
      <HeadLine text="댓글관리" extraBold />
      <Space y={37} />
      <div className="p-32 bg-white rounded-xl">
        <Title text={`${max}개의 댓글`} />
        <Space y={23} />
        <Table
          rowKey="index"
          size="middle"
          pagination={{ position: ["bottomCenter"] }}
          columns={columns}
          dataSource={commentList}
          onRow={(record) => {
            return {
              onClick: (e) => {
                const target: any = e.target;
                setSelectedRowIdx(record.index);
                const isSwitch = target.classList.value.includes("switch"); // 스위치 클릭 여부
                !isSwitch && dispatch(modalToggle(1));
              },
            };
          }}
        />
      </div>
      {selectedRowIdx !== -1 && (
        <CommnetModal
          row={
            commentList.find((el) => el.index === selectedRowIdx) ||
            ({} as CommentT)
          }
          activeToggle={activeToggle}
        />
      )}
    </div>
  );
};

export default Comment;
