import { Route, Routes } from "react-router-dom";
import Layout from "./Layout/layout";
import Login from "Pages/Login/login";

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ReducerType, useAppDispatch } from "Store/store";
import { logout } from "Store/Slices/user";
import { User } from "types/interface";
import { accessToken } from "Util/function";
import Topic from "Pages/Topic/topic";
import useSetUser from "Util/Hooks/useTokenCheck";
import Post from "Pages/Post/post";
import Comment from "Pages/Comment/comment";
import Account from "Pages/Account/account";
import List from "Pages/List/list";
import Report from "Pages/Report/report";
import Point from "Pages/Point/point";
import Goods from "Pages/Goods/goods";

const App = () => {
  const dispatch = useAppDispatch();
  const user: User = useSelector((state: ReducerType) => state.user);
  const set = useSetUser();

  useEffect(() => {
    if (accessToken.get()) {
      set();
    } else {
      dispatch(logout());
    }
  }, []);

  if (user.idx === -1) return <div></div>;

  return (
    <div id="App">
      {user.idx === 0 ? (
        <Routes>
          <Route path="*" element={<Login />} />
        </Routes>
      ) : (
        <Layout>
          <Routes>
            <Route path="/" element={<Topic />} />
            <Route path="/topic" element={<Topic />} />
            <Route path="/post" element={<Post />} />
            <Route path="/comment" element={<Comment />} />
            <Route path="/account" element={<Account />} />
            <Route path="/list" element={<List />} />
            <Route path="/report" element={<Report />} />
            <Route path="/point" element={<Point />} />
            <Route path="/goods" element={<Goods />} />
            <Route path="/setting" element={<Topic />} />
          </Routes>
        </Layout>
      )}
    </div>
  );
};

export default App;
