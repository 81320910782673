import { accessToken, refreshToken } from "Util/function";
import { deleteApi, getApi, postApi, putApi } from "./baseApis";
import { AxiosResponse } from "axios";
import * as T from "types/interface";

// 로그인
export const loginApi = async (account: {
  email: string;
  password: string;
}) => {
  const url = `/admin/signin`;
  return await postApi(url, account);
};

// 토큰 검증
export const checkTokenApi = async () => {
  const url = `/auth/verify-token`;
  const body = {
    accessToken: accessToken.get(),
  };
  return await postApi(url, body);
};

// access토큰 재발급
export const getAccessApi = async () => {
  const url = `/admin/refresh`;
  const body = {
    refreshToken: refreshToken.get(),
  };
  return await postApi(url, body);
};

// 토픽
export const topicApi = {
  get: async () => {
    const url = `/topics?page=1&limit=10000000000&indexOrder=asc`;
    const data: Promise<
      AxiosResponse<T.ApiResponesT<{ topicsCount: number; topics: T.TopicT[] }>>
    > = await getApi(url);
    return data;
  },
  putHidden: async (topicId: number) => {
    const url = `/topics/hide`;
    const data: Promise<AxiosResponse<T.ApiResponesT<{ hidden: boolean }>>> =
      await postApi(url, { topicId });
    return data;
  },
};

// 포스트
export const postsApi = {
  get: async () => {
    const url = `/posts?page=1&limit=10000000000&indexOrder=asc`;
    const data: Promise<
      AxiosResponse<T.ApiResponesT<{ postsCount: number; posts: T.PostT[] }>>
    > = await getApi(url);
    return data;
  },
  putHidden: async (postId: number) => {
    const url = `/posts/hide`;
    const data: Promise<AxiosResponse<T.ApiResponesT<{ hidden: boolean }>>> =
      await postApi(url, { postId });
    return data;
  },
};

// 댓글
export const commentApi = {
  get: async () => {
    const url = `/posts/comments?page=1&limit=10000000000&indexOrder=asc`;
    const data: Promise<
      AxiosResponse<
        T.ApiResponesT<{ commentsCount: number; comments: T.CommentT[] }>
      >
    > = await getApi(url);
    return data;
  },
  putHidden: async (commentId: number, commentType: T.CommentType) => {
    const url = `/posts/comments/hide`;
    const data: Promise<AxiosResponse<T.ApiResponesT<{ hidden: boolean }>>> =
      await postApi(url, { commentId, commentType });
    return data;
  },
};

// 계정
export const accountApi = {
  get: async () => {
    const url = `/users?page=1&limit=10000000000&indexOrder=asc`;
    const data: Promise<
      AxiosResponse<T.ApiResponesT<{ usersCount: number; users: T.AccountT[] }>>
    > = await getApi(url);
    return data;
  },
};

// 리스트
export const listApi = {
  get: async () => {
    const url = `/lists?page=1&limit=10000000000&indexOrder=asc`;

    const data: Promise<
      AxiosResponse<T.ApiResponesT<{ listsCount: number; lists: T.ListT[] }>>
    > = await getApi(url);
    return data;
  },
};

// 신고
export const reportApi = {
  get: async () => {
    const url = `/reports?page=1&limit=10000000000&indexOrder=asc&isPrivate=false`;

    const data: Promise<
      AxiosResponse<
        T.ApiResponesT<{ reportsCount: number; reports: T.ReportT[] }>
      >
    > = await getApi(url);
    return data;
  },
  putAction: async (reportId: number, reportType: T.ReportTypeT) => {
    const url = `/reports`;
    const data: Promise<AxiosResponse<T.ApiResponesT<{ hidden: boolean }>>> =
      await putApi(url, { reportId, reportType });
    return data;
  },
};

// 포인트
export const pointApi = {
  get: async () => {
    const url = `/points?page=1&limit=10000000000&indexOrder=asc&isPrivate=false`;

    const data: Promise<
      AxiosResponse<T.ApiResponesT<{ pointsCount: number; points: T.PointT[] }>>
    > = await getApi(url);
    return data;
  },
  post: async (PostPointReqBody: T.PostPointReqBodyT) => {
    const url = `/points`;
    const data: Promise<AxiosResponse<T.ApiResponesT<{ hidden: boolean }>>> =
      await postApi(url, { PostPointReqBody });
    return data;
  },
};

// 별샵상품
export const goodsApi = {
  get: async () => {
    const url = `/star-shop/products?page=1&limit=10000000000&indexOrder=asc&isPrivate=false`;

    const data: Promise<
      AxiosResponse<
        T.ApiResponesT<{ productsCount: number; products: T.GoodsT[] }>
      >
    > = await getApi(url);
    return data;
  },
  delete: async () => {
    const url = `/star-shop/products`;
    const data: Promise<AxiosResponse<T.ApiResponesT<{ hidden: boolean }>>> =
      await deleteApi(url);
    return data;
  },
  putNotice: async () => {
    const url = `/star-shop/products/notice`;
    const data: Promise<AxiosResponse<T.ApiResponesT<{ hidden: boolean }>>> =
      await deleteApi(url);
    return data;
  },
};
