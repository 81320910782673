import { postsApi } from "Apis/apis";
import Flex from "Components/Atoms/Flex/flex";
import Space from "Components/Atoms/Space/space";
import Caption from "Components/Atoms/Text/caption";
import HeadLine from "Components/Atoms/Text/headLine";
import Title from "Components/Atoms/Text/title";
import Detailmodal from "Components/Molecules/Modal/detailmodal";
import { modalToggle } from "Store/Slices/modal";
import { useAppDispatch } from "Store/store";
import { comma, dateFormat, getIdx } from "Util/function";
import { Table, Switch } from "antd";
import type { TableProps } from "antd";
import { useEffect, useState } from "react";
import { PostT } from "types/interface";
interface PostModalProps {
  row: PostT;
  activeToggle: (idx: number) => {};
}

const TopciModal = ({ row, activeToggle }: PostModalProps) => {
  return (
    <Detailmodal title={`포스트 #${row.index}`} modalNum={1}>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="ID" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.identifier} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="닉네임" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.nickname} />
        </div>
      </Flex>
      <Flex py={12} vertical y="items-start">
        <div className="w-180">
          <Caption text="썸네일" />
        </div>
        <Space y={12} />
        <div className="flex-1">
          <img src={row.thumbnail.backgroundFileURL} alt="" />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="포스트제목" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.postTitle} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="토픽제목" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.topicTitle} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="리스트제목" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.listTitle} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="댓글" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.commentsCount} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="좋아요" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={row.likesCount} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="생성일자" />
        </div>
        <div className="flex-1">
          <Caption color="text-headline" text={dateFormat(row.createdAt)} />
        </div>
      </Flex>
      <Flex py={12}>
        <div className="w-180">
          <Caption text="숨김" />
        </div>
        <Switch
          onChange={() => {
            activeToggle(row.index);
          }}
          checked={row.isHidden}
        />
      </Flex>
    </Detailmodal>
  );
};

const Post = () => {
  const dispatch = useAppDispatch();

  const [postList, setPostList] = useState<PostT[]>([]);
  const [max, setMax] = useState(0);
  const [selectedRowIdx, setSelectedRowIdx] = useState(-1);

  const getTopicList = async () => {
    const {
      data: { data },
    } = await postsApi.get();

    setMax(data.postsCount);
    setPostList(data.posts);
  };

  const activeToggle = async (topicId: number) => {
    const confirm = window.confirm("숨김상태를 변경 하시겠습니까?");
    if (!confirm) return;

    const { data } = await postsApi.putHidden(topicId);
    data.code === "SUCCESS" && getTopicList();
  };

  const columns: TableProps<PostT>["columns"] = [
    {
      title: "idx",
      dataIndex: "index",
      key: "index",
      width: 80,
      sortDirections: ["descend"],
      sorter: (a, b) => a.index - b.index,
      render: (a) => getIdx(postList, a, "index"),
    },
    {
      title: "ID",
      dataIndex: "identifier",
      key: "identifier",
      sorter: (a, b) => a.identifier.localeCompare(b.identifier),
    },
    {
      title: "닉네임",
      dataIndex: "nickname",
      width: 100,
      key: "nickname",
      sorter: (a, b) => a.nickname.localeCompare(b.nickname),
    },
    {
      title: "썸네일",
      dataIndex: "thumbnail",
      width: 100,
      key: "thumbnail",
      render: (thumb) => <img src={thumb.backgroundFileURL} alt="" />,
    },
    {
      title: "포스트제목",
      dataIndex: "postTitle",
      key: "postTitle",
      sorter: (a, b) => a.postTitle.localeCompare(b.postTitle),
    },
    {
      title: "토픽제목",
      dataIndex: "topicTitle",
      key: "topicTitle",
      sorter: (a, b) => a.topicTitle.localeCompare(b.topicTitle),
    },
    {
      title: "리스트제목",
      dataIndex: "listTitle",
      key: "listTitle",
      sorter: (a, b) => a.listTitle.localeCompare(b.listTitle),
    },
    {
      title: "댓글",
      dataIndex: "commentsCount",
      key: "commentsCount",
      width: 100,
      sorter: (a, b) => a.commentsCount - b.commentsCount,

      render: (reward) => comma(reward),
    },
    {
      title: "좋아요",
      dataIndex: "likesCount",
      key: "likesCount",
      width: 100,
      sorter: (a, b) => a.likesCount - b.likesCount,

      render: (reward) => comma(reward),
    },
    {
      title: "생성일자",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 135,
      render: (date) => dateFormat(date),
    },
    {
      title: "숨김",
      dataIndex: "isHidden",
      key: "isHidden",
      width: 100,
      render: (hidden, topic) => (
        <Switch
          onChange={(e) => {
            activeToggle(topic.index);
          }}
          checked={hidden}
        />
      ),
    },
  ];

  useEffect(() => {
    getTopicList();
  }, []);

  return (
    <div>
      <HeadLine text="포스트관리" extraBold />
      <Space y={37} />
      <div className="bg-white rounded-xl p-32">
        <Title text={`${max}개의 포스트`} />
        <Space y={23} />
        <Table
          rowKey="index"
          size="middle"
          pagination={{ position: ["bottomCenter"] }}
          columns={columns}
          dataSource={postList}
          onRow={(record) => {
            return {
              onClick: (e) => {
                const target: any = e.target;
                setSelectedRowIdx(record.index);
                const isSwitch = target.classList.value.includes("switch"); // 스위치 클릭 여부
                !isSwitch && dispatch(modalToggle(1));
              },
            };
          }}
        />
      </div>
      {selectedRowIdx !== -1 && (
        <TopciModal
          row={
            postList.find((el) => el.index === selectedRowIdx) || ({} as PostT)
          }
          activeToggle={activeToggle}
        />
      )}
    </div>
  );
};

export default Post;
