import { checkTokenApi } from "Apis/apis";
import { setUser } from "Store/Slices/user";
import { useAppDispatch } from "Store/store";
import { reAccessToken } from "Util/function";

// 만료토큰
// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwiZW1haWwiOiJ0ZXN0QHRlc3QuY29tIiwiaWF0IjoxNzA3NDg3NjIzLCJleHAiOjE3MDc0ODk0MjN9.CBtLhoaJci-qP8YF85sAP8uTpN4bJpCwuHxc01aqzMo

const useSetUser = () => {
  const dispatch = useAppDispatch();

  const checkTokenAndLogin = async () => {
    const { data } = await checkTokenApi();
    if (data.code === "SUCCESS") {
      const user = {
        idx: 1,
        email: data.data.email,
      };
      dispatch(setUser(user));
    }
  };

  return async () => {
    try {
      checkTokenAndLogin();
    } catch (error) {
      const err: any = error;
      console.log(err.response.data.statusCode);
      reAccessToken(checkTokenAndLogin);
    }
  };
};

export default useSetUser;
